<template>
  <div class="hb-dialog-actions">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { onBeforeUnmount } from 'vue'
import { useDialog } from '~/composables'

export default {
  name: 'HbDialogActions',
  setup(props) {
    const dialog = useDialog(props)
    dialog.value.hasActions.value = true

    onBeforeUnmount(() => (dialog.value.hasActions.value = false))

    return {}
  },
}
</script>

<style lang="scss">
.hb-dialog-actions {
  display: flex;
  gap: 20px;

  > * {
    flex: 1;
  }

  @include mobile-md {
    position: absolute;
    top: calc(100% + 20px);
    left: 0;
    width: 100%;
    flex-wrap: wrap;
    padding: 25px;
    background: var(--hb-white);
    border-radius: 16px;
    flex-direction: column-reverse;
  }
}
</style>
