<template>
  <hb-dialog size="sm">
    <hb-dialog-header>
      {{ title || $t('label.are_you_sure') }}
    </hb-dialog-header>
    <hb-dialog-body>
      <p>
        {{ message }}
      </p>
    </hb-dialog-body>
    <hb-dialog-footer no-border>
      <hb-dialog-actions>
        <hb-btn
          outline
          theme="light"
          size="lg"
          type="button"
          @click="dialog.close(afterClose)"
        >
          <span>{{ $t('label.cancel') }}</span>
        </hb-btn>

        <hb-btn theme="secondary" size="block-lg" @click="accept()">
          <span>{{ btnLabel ?? $t('label.ok') }}</span>
        </hb-btn>
      </hb-dialog-actions>
    </hb-dialog-footer>
  </hb-dialog>
</template>

<script lang="ts">
import HbDialog from '~/components/base/dialog/HbDialog.vue'
import HbDialogFooter from '~/components/base/dialog/HbDialogFooter.vue'
import HbBtn from '~/components/base/utils/HbBtn.vue'
import HbDialogBody from '~/components/base/dialog/HbDialogBody.vue'
import HbDialogHeader from '~/components/base/dialog/HbDialogHeader.vue'
import { useDialog } from '~/composables'
import { DialogMixin } from '~/mixins'
import HbDialogActions from '~/components/base/dialog/HbDialogActions.vue'

export default {
  name: 'ConfirmDialog',
  components: {
    HbDialogActions,
    HbDialogHeader,
    HbDialogBody,
    HbBtn,
    HbDialogFooter,
    HbDialog,
  },
  mixins: [DialogMixin],
  props: {
    message: { type: String, required: true },
    title: { type: String, default: () => null },
    btnLabel: { type: String, default: () => null },
    onAccept: { type: Function, default: () => {} },
  },
  setup(props) {
    const dialog = useDialog(props)
    const accept = () => {
      props.onAccept()
      dialog.value.close(props.afterClose)
    }

    return {
      dialog,
      accept,
    }
  },
}
</script>

<style scoped lang="scss">
p {
  font-weight: 500;
  letter-spacing: -0.48px;
  line-height: 28px;
}
</style>
